import template from './personalDetails.html';
import styling from './personalDetails.less';

export default angular.module('eventix.shop.personalDetails',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.personalDetails', {
            url: '/personalDetails',
            views: {
                shop: {
                    controller: 'PersonalDetailsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .directive('repaint', function($timeout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {
                $timeout(() => el.css({ zIndex: 4 }), 500);
            }
        };
    })
    .controller('PersonalDetailsController', function($state, $scope, shop, order, products, tickets, LaravelValidationRules, $filter,
        Rollbar, FB, $q, UIMessages, VisitorData, $analytics, OAuthTokens, MetaData, ShopAnalytics) {
        var vm = this;
        if(_.flatten(_.values(order.reservations)).length < 1)
            $state.go('eventix.shop.events', {shopId: shop.guid});
        if(order.receiver.email || FB.disabled) // Don't show facebook login button if profile is already filled
            vm.manualEntry = true;
        vm.order = order;
        vm.shop = shop;
        vm.tickets = tickets;
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');

        vm.products = products;
        vm.verifyEmail = function(error, valid, suggestion) {
            if(!suggestion)
                return;
            if(!_.get(vm.order.receiver,'$errors.email'))
                _.set(vm.order.receiver, '$errors.email', []);
            vm.order.receiver.$errors.email.push($filter('translate')('common.notice.didYouMean', {suggestion: suggestion }));
        };

        vm.back = () => {
            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.tickets', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.tickets', { shopId: shop.guid, eventId });
        };
        vm.next = () => {
            let unopened = _.findKey(vm.hasOpened, v => !v);
            if(unopened) {
                let toOpen = _.find($scope.accordion.groups, g => _.get(g.$parent,'ticketReservation.reservation') === unopened);
                if(toOpen) {
                    vm.hasOpened[unopened] = true;
                    toOpen.toggleOpen();
                    return;
                }
            }
            let invalid = order.validate();
            if(!invalid) {
                Rollbar.configure({
                    payload: {
                        person: {
                            id: VisitorData.visitorId,
                            username: [order.receiver.firstname, order.receiver.lastname].join(' '),
                            email: order.receiver.email
                        },
                        token: _.get(OAuthTokens, 'client_credentials.access_token')
                    }
                });
                $state.go('eventix.shop.summary',{ shopId: shop.guid });
            } else {
                const ticketReservations = _.flatten(_.values(vm.order.reservations));
                const invalidReservation = _.find(ticketReservations, r => r.$invalid());
                const invalidReceiver = $filter('hasNestedErrors')(vm.order.receiver.$errors);
                let collapseToOpen;
                if(!invalidReservation || invalidReceiver) {
                    // Find the collapse without a ticketReservation
                    collapseToOpen = _.find($scope.accordion.groups, group => !group.$parent.ticketReservation);
                } else {
                    // Find the collapse with the invalid reservation
                    collapseToOpen = _.find($scope.accordion.groups, group => {
                        const collapseReservation = _.get(group, '$parent.ticketReservation.reservation');
                        return collapseReservation === invalidReservation.reservation;
                    });
                }
                if(!collapseToOpen.isOpen)
                    collapseToOpen.toggleOpen();
            }
        };

        ShopAnalytics.checkoutStepOne();
        /*
        * We keep tabs on whether people understand the accordion.
        * If they have unopened tabs, goto that tab when you press "Next"
        */
        vm.hasOpened = { };
        _.forEach(vm.order.reservations, (ticketReservations, ticketGuid) => {
            if (!vm.order.showTicketReservationForm(ticketGuid))
                return;
            _.forEach(ticketReservations, reservation => {
                vm.hasOpened[reservation.reservation] = false;
            });
        });
        vm.logOpeningCollapse = function(reservationGuid) {
            vm.hasOpened[reservationGuid] = true;
        };

        vm.facebookLogin = function() {
            order.loginWithFacebook().catch(() => {
                UIMessages.push('common.shop.facebookError');
                return $q.resolve();
            }).then(() => {
                vm.manualEntry = true;
            });
        };
    }).name;
